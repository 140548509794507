import React, { useEffect } from "react";
import { testUrlValid } from "../../Constants";
import { getLastUrl, getUser, isAuthenticated } from "../../auth/Auth";
import { generateOtp } from "../../services/AuthServices";

function Account() {
  useEffect(() => {
    const handleRedirect = async () => {
      let lastUrl = getLastUrl();

      let cond = testUrlValid(lastUrl);

      if (cond) {
        if (isAuthenticated()) {
          const response = await generateOtp(getUser().id);
          const otp = response.data.otp.otp;
          if (otp) {
            window.location.assign("http://" + lastUrl + "?otp=" + otp);
          }
        }
      }
    };
    handleRedirect();
  }, []);

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <></>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Account;
