import React from "react";
import { useState } from "react";
import SignUp from "../components/signUp";
import LanguageSelector from "../components/language/LanguageSelector";

function CreateAccount(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  return (
    <>
      <div className="header-ct-right" style={{width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "50px", paddingRight: "50px"}}>
        <LanguageSelector />
      </div>
      <SignUp />
    </>
  );
}

export default CreateAccount;
