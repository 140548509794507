import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {TabPanel, Tabs} from "react-tabs";

function SuccessAccount() {

    return (
        <section className="account-section">
            <div className="tf-container">
                <div className="row">
                    <Tabs className="wd-form-login tf-tab">
                        <img src='media-intelligence-icon.png' className="mb-4" alt="logo"/>
                        <h4>Bravo!</h4>
                        <h5>Vous avez crée un compte avec succès!</h5>

                        <Link to="/login">
                            <button type="button"
                                //disabled={!is_checked}
                            >Connexion
                            </button>
                        </Link>


                    </Tabs>
                </div>
            </div>
        </section>

    );
}

export default SuccessAccount;
