import axios from 'axios';



// Ajoutez ici l'URL de votre API
const API_URL = process.env.REACT_APP_BACKEND_SERVICE+"/api";

const axiosInstance = axios.create({
    baseURL: API_URL
});

// Ajoutez un intercepteur pour ajouter le token JWT au header de chaque requête sortante
axiosInstance.interceptors.request.use(
    (config) => {
        const token = `${localStorage.getItem("access_token")}`;
        if (token && typeof token === 'string') {
           const mytoken= token.replace(/^"|"$/g, '') // to remove double quotes ("") from the token
            config.headers['Authorization'] = `Bearer ${mytoken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);    

export default  axiosInstance;
