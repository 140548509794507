import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function useLanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language).then(() => {
      localStorage.setItem("i18nextLng", language);
    });
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("i18nextLng") || "en";
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  return { changeLanguage };
}
