import React, { useContext } from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import i18n from "i18next";
import { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import ScrollToTop from "./ScrollToTop";
import { setLastUrl } from "./auth/Auth";
import Account from "./components/account";
import GoogleSignIn from "./components/googlesignIn";
import SignOut from "./components/logout";
import Preloader from "./components/preloader";
import SocialMediaRedirection from "./components/socialmediaredirection";
import SuccessAccount from "./components/success-account-creation";
import { AuthContext } from "./context/AuthContext";
import translationDE from "./locales/de/translation.json";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import CreateAccount from "./pages/CreateAccount";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  de: {
    translation: translationDE,
  },
};

function getCurrentLanguage() {
  const savedLanguage = localStorage.getItem("i18nextLng");

  if (savedLanguage && resources[savedLanguage]) {
    return savedLanguage;
  } else {
    const defaultLanguage = "en";
    localStorage.setItem("i18nextLng", defaultLanguage);
    return defaultLanguage;
  }
}

export const i18nConfig = i18n.use(initReactI18next).init({
  resources,
  lng: getCurrentLanguage(),
  fallbackLng: "en",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});
const App = () => {
  let routes = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/myaccount", element: <Account /> },
    { path: "/socialMediaRedirect", element: <GoogleSignIn /> },
    { path: "/logout", element: <SignOut /> },
    { path: "/register", element: <CreateAccount /> },
    { path: "/loginFromSocial", element: <SocialMediaRedirection /> },
    { path: "/email-verify-success", element: <SuccessAccount /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  const [loading, setLoading] = useState(true);
  const { connected, user, afterLoginLight } = useContext(AuthContext);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    const fetchLoginUrl = () => {
      //get the url of the thirdParty using sso
      const queryParams = new URLSearchParams(window.location.search);
      let microserviceUrl = queryParams.get("redirect");
      console.log(microserviceUrl);

      microserviceUrl !== null
        ? setLastUrl(microserviceUrl)
        : console.log("Url is empty");

      //get the url of the sso
      const url = new URL(window.location.href);
      const domain = url.origin;
      //setLastUrl(domain)
      console.log(microserviceUrl);
      if (microserviceUrl) {
        console.log(localStorage.getItem("user"));
        /* if(isAuthenticated()){
          console.log("je suis bien authentifié");
          //if authenticated, the user is redirected to my account with the right link
          window.location.assign("http://"+microserviceUrl);
        }*/
      }
    };
    fetchLoginUrl();
  }, []);

  return (
    <>
      {!loading ? (
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default AppWrapper;
