import React, { useEffect, useState } from "react";
import { testUrlValid } from "../../Constants";
import { getLastUrl, getUser, signIn } from "../../auth/Auth";
import { generateOtp, getUserByMail } from "../../services/AuthServices";
import Preloader from "../preloader";

function GoogleSignIn() {
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = async (e) => {
    //const lastUrl = getLastUrl();
    //console.log(lastUrl)

    setIsLoading(true);
    try {
      const lastUrl = getLastUrl();
      console.log(lastUrl);
      let url = new URL(window.location.href);

      // Récupérer les paramètres
      let email = url.searchParams.get("email");
      console.log(email);

      const mail = {
        email: email,
      };
      // setLoading(true);
      const response = await getUserByMail(mail);
      console.log(response);
      signIn(response);
      let cond = testUrlValid(lastUrl);
      const res = await generateOtp(getUser().id);
      console.log(res);
      const otp = res.data.otp.otp;
      if (cond == true) {
        console.log("Url validated");
        if (otp) {
          setAlertMessage(
            "Success! Your OTP has been sent to your email. Please check your email"
          );
          //lastUrl = 'http://localhost:3001'
          //location.replace(lastUrl + "?otp=" + otp);
          window.location.replace(lastUrl + "?otp=" + otp);

          //return;
        }
      }
    } catch (error) {
      console.log(error);

      if (error.status == 400) {
        setError(error.data.message);
      }
      if (error.status === 403) {
        let email = error.data.message;
        console.log(email);
      } else {
        // showAlert("error", error.data.message)
        //console.log(error.data.message);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loginUser();
  }, []);

  return (
    <>
      <Preloader />
    </>
  );
}

export default GoogleSignIn;
