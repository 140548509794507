import React, { createContext, useEffect, useState } from "react";
import { getCarts } from "../services/ArticleServices";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [myCart, setMyCart] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(null);

  useEffect(() => {
    //setConnected(false);
    let local = localStorage.getItem("access_token");
    console.log("la data");
    console.log(local);
    if (local !== null && local !== undefined && local !== "undefined") {
      try {
        console.log("dedededee data");
        let result = localStorage.getItem(user);
        setUser(result);
        setConnected(true);
      } catch (error) {}
    } else {
      console.log("false false");
      setConnected(false);
    }

    // try {
    //     let result = await getAdmin();
    //     setAdmin(result);
    // } catch (error) {
    // }

    /*         const retrieveSelectedCategories = async () => {
            let data = await getSelectedCategories();
            let arr = Object.values(data);
      
            let myCats[] = [];
      
            for (var i in arr) {
              var item = arr[i];
              myCats.push({
                "name" : item.name,
                "desc": item.number_of_articles + " articles",
                "img": item.image,
                "uid": item.category_id
              });
            }
            setSelectedCategories(myCats);
        }

        retrieveSelectedCategories(); */
  }, [user]);

  const logOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user_id");
    setConnected(false);
    window.location.href = "/";
  };

  const afterLogin = async () => {
    if (
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token")
    ) {
      try {
        setUser(localStorage.getItem("user"));
        setConnected(true);
      } catch (error) {}
    } else {
      setConnected(false);
    }
  };

  const afterLoginLight = async () => {
    if (localStorage.getItem("access_token")) {
      try {
        setConnected(true);
      } catch (error) {}
    }
  };

  const afterAddToCard = async () => {
    if (
      localStorage.getItem("access_token") ||
      sessionStorage.getItem("access_token")
    ) {
      try {
        let data = await getCarts(user?.id);
        setMyCart(data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        connected,
        user,
        admin,
        afterLogin,
        afterLoginLight,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
