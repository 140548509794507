import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { testUrlValid } from "../../Constants";
import { getLastUrl, getUser, signIn } from "../../auth/Auth";
import { EditPassword, generateOtp } from "../../services/AuthServices";

function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { t, i18n } = useTranslation();

  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const loginUser = async (e) => {
    try {
      e.preventDefault();

      const data = {
        email: localStorage.getItem("temp_email"),
        password: password,
        password_confirmation: password_confirmation,
      };
      // setLoading(true);
      const response = await EditPassword(data);

      signIn(response);
      const lastUrl = getLastUrl();
      let cond = testUrlValid(lastUrl);

      if (cond) {
        const response = await generateOtp(getUser().id);
        const otp = response.data.otp.otp;
        if (otp) {
          setAlertMessage(
            "Success! Your OTP has been sent to your email. Please check your email"
          );
          console.log("ready for redirection");
          window.location.replace(lastUrl + "?otp=" + otp);
        }
        return;
      }
      return;
    } catch (error) {
      if (error.status === 403) {
        let email = error.data.message;
      } else {
        //console.log(error.data.message);
      }
    }
    //setLoading(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login">
            <img
              src="media-intelligence-icon.png"
              className="mb-4"
              alt="logo"
            />
            <h4>{t("account.AccountpasswordReset")}</h4>
            <form action="/">
              <div className="ip">
                <label>
                  {t("account.AccountPass")}
                  <span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    placeholder={t("account.AccountPass")}
                    id="password-input"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link
                    className={`password-addon ${
                      showPass ? "icon-eye" : "icon-eye-off"
                    }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="ip">
                <label>
                  {t("account.AccountConfirmPass")}
                  <span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    placeholder={t("account.AccountPass")}
                    id="password-input"
                    required
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                  <Link
                    className={`password-addon ${
                      showPass ? "icon-eye" : "icon-eye-off"
                    }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <button type="submit" onClick={loginUser}>
                {t("account.AccountConnexion")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;
