import React, { useEffect } from "react";
import { logout } from "../../auth/Auth";
import Preloader from "../preloader";
function SignOut() {
  useEffect(() => {
    const handleRedirect = async () => {
      logout();
      window.location.replace(process.env.REACT_APP_CLIENT_SERVICE);
    };
    handleRedirect();
  }, []);

  return (
    <>
      <Preloader />
    </>
  );
}

export default SignOut;
